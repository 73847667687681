<template>
  <b-container
    class="mb-5"
    fluid
  >
    <b-breadcrumb
      v-if="$route.meta.breadcrumb"
      :items="$route.meta.breadcrumb || []"
    />
    <b-row>
      <b-col
        cols="12"
      >
        <apexchart
          width="500"
          type="pie"
          :options="graphOne.chartOptions"
          :series="graphOne.series"
        />
      </b-col>
      <b-col
        cols="6"
      >
        <apexchart
          type="bar"
          :options="graphTwo.chartOptions"
          :series="graphTwo.series"
        />
      </b-col>
      <b-col
        cols="6"
      >
        <apexchart
          type="bar"
          :options="graphThree.chartOptions"
          :series="graphThree.series"
        />
      </b-col>
      <b-col
        cols="6"
      >
        <apexchart
          type="bar"
          :options="graphFour.chartOptions"
          :series="graphFour.series"
        />
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import { core } from '@/config/pluginInit'
import { SharedStatisticsService } from '@/services'

export default {
  name: 'SupervisorGraphCRM',

  middleware: ['auth', 'supervisor'],

  metaInfo () {
    return {
      title: 'CRM Graph'
    }
  },

  data () {
    return {
      graphOne: {
        chartOptions: {
          dataLabels: {
            enabled: true
          }
        },
        series: []
      },
      graphTwo: {
        series: [],
        chartOptions: {
          chart: {
            type: 'bar',
            height: 350
          },
          plotOptions: {
            bar: {
              horizontal: false,
              columnWidth: '55%',
              endingShape: 'rounded'
            }
          },
          dataLabels: {
            enabled: false
          },
          stroke: {
            show: true,
            width: 2,
            colors: ['transparent']
          },
          // xaxis: {
          // categories: ['Central Luzon', 'East VisMin', 'HVC', 'Isabela', 'Mindanao', 'North Luzon', 'South Luzon', 'Visayas']
          // },
          yaxis: {
            title: {
              text: 'Count'
            }
          },
          fill: {
            opacity: 1
          },
          tooltip: {
            y: {
              formatter: function (val) {
                return val
              }
            }
          }
        }
      },
      graphThree: {
        series: [],
        chartOptions: {
          chart: {
            type: 'bar',
            height: 350
          },
          plotOptions: {
            bar: {
              horizontal: false,
              columnWidth: '55%',
              endingShape: 'rounded'
            }
          },
          dataLabels: {
            enabled: false
          },
          stroke: {
            show: true,
            width: 2,
            colors: ['transparent']
          },
          // xaxis: {
          // categories: ['Central Luzon', 'East VisMin', 'HVC', 'Isabela', 'Mindanao', 'North Luzon', 'South Luzon', 'Visayas']
          // },
          yaxis: {
            title: {
              text: 'Count'
            }
          },
          fill: {
            opacity: 1
          },
          tooltip: {
            y: {
              formatter: function (val) {
                return val
              }
            }
          }
        }
      },
      graphFour: {
        series: [{
          data: []
        }],
        chartOptions: {
          chart: {
            type: 'bar',
            height: 350
          },
          plotOptions: {
            bar: {
              borderRadius: 4,
              horizontal: true
            }
          },
          dataLabels: {
            enabled: false
          }
          // xaxis: {
          //   categories: ['South Korea', 'Canada', 'United Kingdom', 'Netherlands', 'Italy', 'France', 'Japan',
          //     'United States', 'China', 'Germany'
          //   ]
          // }
        }
      }
    }
  },

  mounted () {
    core.index()
    this.getGraphCRM()
  },

  methods: {
    async getGraphCRM () {
      await SharedStatisticsService.getGraphCRM()
        .then(({ data }) => {
          this.graphOne.chartOptions = { labels: data.promotion_vs_lead.labels }
          this.graphOne.series = data.promotion_vs_lead.series
          this.graphTwo.chartOptions = { xaxis: { categories: data.promotion_vs_cluster.categories } }
          this.graphTwo.series = data.promotion_vs_cluster.series
          this.graphThree.chartOptions = { xaxis: { categories: data.promotion_vs_land.categories } }
          this.graphThree.series = data.promotion_vs_land.series
          this.graphFour.chartOptions = { xaxis: { categories: data.promotion_category.categories } }
          this.graphFour.series[0].data = data.promotion_category.series
        })
    }
  }
}
</script>
